import React from "react";
import InvitationListItem from "./ListWithTransitionBarItem";
import { useTranslation } from "react-i18next";
const ListWithTransitionBar = ({ data, active, setActive, setPage }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div
        className="users-list"
        style={
          i18n.language === "ar"
            ? { marginRight: "-17px !important" }
            : { marginLeft: "-17px !important" }
        }
      >
        <ul>
          {data.map((item) => {
            return (
              <InvitationListItem
                active={active}
                onClick={() => {
                  setActive(item.id);
                  setPage(1);
                }}
                key={item.id}
                item={item}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ListWithTransitionBar;
