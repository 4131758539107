import React from "react";
import { useTranslation } from "react-i18next";
const InvitationListItem = React.memo(({ item, active, onClick, disabled }) => {
  const { t } = useTranslation();
  return (
    <li
      key={item.id}
      onClick={onClick}
      className={active === item.id ? "active-item" : ""}
    >
      {" "}
      {t(item.title)}
      {active === item.id ? <hr id="list-hr" /> : null}
    </li>
  );
});

export default InvitationListItem;
